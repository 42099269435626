import { PRODUCT_BACON, SHOP_LIST_BY_ONE_PRODUCT } from 'lib/constants';

export function isShopListByOneProduct(subType) {
  return subType === SHOP_LIST_BY_ONE_PRODUCT;
}

/**
 * Helper function to determine if a package is a Product Bacon.
 * @param {string} subType
 */
export function isProductBacon(subType) {
  return subType === PRODUCT_BACON;
}
