import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getDataActivityMapID } from 'lib/articleUtils';
import { shouldDisplayEcommerceDisclaimerAlternateFormat } from 'lib/commerceUtils';
import styles from './styles.module.scss';

function DisclaimerDefault({
  type,
  verbiage = '',
}) {
  if (!verbiage) {
    return null;
  }

  const html = {
    __html: verbiage,
  };

  const alternateFormat = shouldDisplayEcommerceDisclaimerAlternateFormat(type);

  const dataActivityMapID = getDataActivityMapID(
    {
      componentName: 'disclaimer',
      pageRegion: 'article-top',
    },
  );
  return (
    <section
      data-activity-map={dataActivityMapID}
      data-test="ecommerce-disclaimer"
      data-testid="ecommerce-disclaimer"
      className={classNames(
        'ecommerce-disclaimer',
        styles.disclaimer,
        { [styles.disclaimerAlternate]: alternateFormat },
      )}
    >
      <p
        data-test="ecommerce-disclaimer__content"
        data-testid="ecommerce-disclaimer__content"
        className={classNames('ecommerce-disclaimer__content', styles.content)}
        dangerouslySetInnerHTML={html} // eslint-disable-line react/no-danger
      />
    </section>
  );
}

DisclaimerDefault.propTypes = {
  type: PropTypes.string.isRequired,
  verbiage: PropTypes.string,
};


export { DisclaimerDefault };
