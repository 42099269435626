import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { getInfoForShopButtons } from 'lib/teaseUtils';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import contentPropType from 'lib/CustomPropTypes/content';
import { isOnSale } from 'lib/productOfferPriceHelpers';

import Link from 'components/Link/index';
import ShopButton from 'components/ShopButtonInfo/ShopButton';
import TeasePicture from 'components/TeasePicture/index';
import styles from './styles.module.scss';

const TEASE_SIZES = {
  s: AIMS_FLAVORS.FOCAL_440x440,
};

function renderHeadline({
  headlineText, url, target, rel, trackLink, productId,
}) {
  return (
    <h3 className={styles.baconCardsOneByOneProductHeadline}>
      <Link
        data-product-id={productId}
        className={styles.baconCardsOneByOneProductHeadlineLink}
        rel={rel}
        target={target}
        to={url}
        onClick={trackLink}
      >
        {headlineText}
      </Link>
    </h3>
  );
}

function renderShopButtons(props) {
  const {
    card: { isCustomAd, id },
    openInNewTab,
    trackLink,
  } = props;

  if (isCustomAd) { return null; }

  const {
    list,
    productName,
    productUrl: url,
    seller,
  } = getInfoForShopButtons(props);

  return (
    <Link
      data-product-id={id}
      className={styles.baconCardsOneByOneProductButton}
      rel={openInNewTab ? 'sponsored noopener noreferrer' : ''}
      target={openInNewTab ? '_blank' : '_self'}
      to={url}
      onClick={trackLink}
    >
      <ShopButton
        additionalStyles={{
          shop: styles.baconCardsOneByOneProductButtonShop,
          vendor: styles.baconCardsOneByOneProductButtonVendor,
        }}
        block="BaconProduct"
        isLink
        isWaffle={false}
        isBacon
        listPrice={list}
        name={productName || ''}
        seller={seller?.name ?? ''}
        trackingCode="mbt_bacon_click"
      />
    </Link>
  );
}

function renderPrice(listPrice, salePrice) {
  if (!listPrice) { return null; }

  const hasSalePrice = isOnSale({ salePrice, listPrice });

  const priceEl = (
    <>
      {hasSalePrice && (
        <div>
          $
          {salePrice}
        </div>
      )}
      <div>
        $
        {listPrice}
      </div>
    </>
  );

  return (
    <div className={classNames(
      styles.baconCardsOneByOneProductPrice,
      {
        [styles.baconCardsOneByOneProductPriceSale]: hasSalePrice,
      },
    )}
    >
      {priceEl}
    </div>
  );
}

function renderDescription({ card = {} }) {
  const {
    computedValues: {
      dek,
    } = {},
    item: {
      description: {
        primary: description = '',
      } = {},
    } = {},
  } = card;

  const dekDisplayText = dek || description;

  if (!dekDisplayText) { return null; }

  return (
    <div className={styles.baconCardsOneByOneProductDescription}>
      {dekDisplayText}
    </div>
  );
}

const OneByOneProduct = (props) => {
  const {
    card: {
      computedValues = {},
      computedValues: {
        headline: headlineText = '',
      } = {},
      type,
      id,
      isLazyLoaded = true,
    } = {},
    openInNewTab = true,
    width = 260,
    trackLink = () => {},
  } = props;

  const target = openInNewTab ? '_blank' : '_self';
  const rel = openInNewTab ? 'sponsored noopener noreferrer' : '';
  const {
    list,
    sale,
    productUrl: url,
  } = getInfoForShopButtons(props);

  return (
    <div
      className={styles.baconCardsOneByOne}
      data-test="one-by-one__container"
      data-contentid={id}
    >
      <Link
        data-product-id={id}
        className="db relative"
        rel={rel}
        target={target}
        to={url}
        onClick={trackLink}
      >
        <TeasePicture
          computedValues={computedValues}
          height={width}
          lazyload={isLazyLoaded}
          responsiveFlavors={TEASE_SIZES}
          type={type}
          width={width}
        />
      </Link>
      {renderShopButtons(props)}
      {renderHeadline({
        headlineText,
        url,
        target,
        rel,
        productId: id,
        trackLink,
      })}
      {renderDescription(props)}
      {renderPrice(list, sale)}
    </div>
  );
};

OneByOneProduct.propTypes = {
  card: contentPropType,
  openInNewTab: PropTypes.bool,
  width: PropTypes.number,
  trackLink: PropTypes.func,
};


export { OneByOneProduct };
