import { sectionContainSlug } from 'lib/taxonomy';


// eslint-disable-next-line max-len
export const ECOMMERCE_ABOUT_URL_TODAY = 'https://www.today.com/shop/everything-you-need-know-about-shop-today-t161374';

export const ECOMMERCE_DISCLAIMER = {
  DEFAULT: 'Our editors independently selected these items because we think you will enjoy them '
    + 'and might like them at these prices. If you purchase something through our links, we may '
    + 'earn a commission. Pricing and availability are accurate as of publish time.',

  PRIME_DAY: 'Prime Day is an event created by Amazon. Our editors independently selected these '
    + 'items because we think you will enjoy them and might like them at these prices. If you '
    + 'purchase something through our links, we may earn a commission. Pricing and availability '
    + 'are accurate as of publish time.',

  TARGET_SHOP_THIS_LIST: 'This content is sponsored by Target. '
    + 'Our editorial team independently selected these products. '
    + 'When you buy through our links, we earn a commission. ',

  SELECT_CUSTOMER: 'Select is editorially independent. We may earn a commission from affiliate '
    + 'partner links. Read more about Select on '
    + '<a href="https://www.nbcnews.com/select/about" target="_blank">NBC News</a>, '
    + 'and click <a href="https://www.cnbc.com/select/disclosures/" target="_blank">here</a> '
    + 'to read our advertiser disclosure.',

  PRODUCT: '<span class="disclaimer__lead-in">DISCLAIMER</span>: Our editors independently '
    + 'selected these items because we think you '
    + 'will enjoy them and might like them at these prices. If you purchase something '
    + 'through our links, we may earn a commission. Pricing and availability are accurate '
    + `as of publish time. <a href="${ECOMMERCE_ABOUT_URL_TODAY}">Learn more about Shop TODAY</a>`,

  SELECT_DEFAULT: 'Select is editorially independent. Our editors selected these deals and items '
    + 'because we think you will enjoy them at these prices. If you purchase something through our '
    + 'links, we may earn a commission. Pricing and availability are accurate as of publish time.',
};

/**
 * @param {Array} subtopics
 * @returns {boolean}
 */
export const containsDealsSubtopic = (subtopics) => {
  const dealsSubtopic = 'deals';

  const containsSubtopic = Array.isArray(subtopics)
    && !!(subtopics.find(({ slug }) => slug === dealsSubtopic));

  return containsSubtopic;
};

/**
 * @param {Array} labels
 * @returns {boolean}
 */
export const containsShopDealsLabel = (labels) => {
  const shopDealsLabel = 'shop-deals';

  const labelsContainsShopDealsLabel = Array.isArray(labels)
    && !!(labels.find(({ slug }) => slug === shopDealsLabel));

  return labelsContainsShopDealsLabel;
};

/**
 * @param {Array} labels
 * @returns {boolean}
 */
export const containsSponsoredCommerceLabel = (labels) => {
  const sponsoredCommerceLabel = 'sponsored-commerce';

  const containsLabel = Array.isArray(labels)
    && !!(labels.find(({ slug }) => slug === sponsoredCommerceLabel));

  return containsLabel;
};

/**
 * @param {Array} sections
 * @returns {boolean}
 */
export const containsShoppingSection = (sections) => {
  const shoppingSectionSlug = 'shopping';

  return sectionContainSlug(sections, shoppingSectionSlug);
};

/**
 * @param {object} source
 * @returns {boolean}
 */
export const isSourceFromStackCommerce = (source) => {
  const organizationName = source?.organization?.name;

  const organizationIsStackCommerce = (organizationName === 'StackCommerce');

  return organizationIsStackCommerce;
};

/**
 * @param {string} vertical
 * @param {object} article
 * @returns {string}
 */
export const getEcommerceArticleType = (vertical, article = {}) => {
  const { ecommerceEnabled, source, taxonomy = {} } = article;
  const { labels, subtopics, sections } = taxonomy;

  const articleContainShopDealsLabel = containsShopDealsLabel(labels);
  const articleContainsSponsoredCommerceLabel = containsSponsoredCommerceLabel(labels);

  const articleContainDealsSubtopic = containsDealsSubtopic(subtopics);

  const articleContainsShoppingSection = containsShoppingSection(sections);
  const articleDoesNotContainShoppingSection = !articleContainsShoppingSection;

  const articleSourceIsStackCommerce = isSourceFromStackCommerce(source);

  const isTodayVertical = (vertical === 'today');
  const isNotTodayVertical = !isTodayVertical;

  const isNBCNewsVertical = (vertical === 'news');

  const isTodayDeals = ecommerceEnabled
    && isTodayVertical
    && (articleContainShopDealsLabel || articleContainDealsSubtopic);
  if (isTodayDeals) {
    return 'todayDeals';
  }

  const isTodaySponsoredCommerce = ecommerceEnabled
    && isTodayVertical
    && articleContainsSponsoredCommerceLabel;
  if (isTodaySponsoredCommerce) {
    return 'todaySponsoredCommerce';
  }

  const isTodayGeneralCommerce = ecommerceEnabled && isTodayVertical;
  if (isTodayGeneralCommerce) {
    return 'todayGeneralCommerce';
  }

  const isNBCNewsGeneralCommerceStackCommerce = ecommerceEnabled
    && isNBCNewsVertical
    && articleSourceIsStackCommerce;
  if (isNBCNewsGeneralCommerceStackCommerce) {
    return 'nbcNewsGeneralCommerceStackCommerce';
  }

  const isNBCNewsGeneralCommerceShopToday = ecommerceEnabled
    && isNotTodayVertical
    && articleContainsShoppingSection;
  if (isNBCNewsGeneralCommerceShopToday) {
    return 'nbcNewsGeneralCommerceShopToday';
  }

  const isNBCNewsGeneralCommerce = ecommerceEnabled
    && isNotTodayVertical
    && articleDoesNotContainShoppingSection;
  if (isNBCNewsGeneralCommerce) {
    return 'nbcNewsGeneralCommerce';
  }

  return 'nbcNewsGeneric';
};

/**
 * @param {string} ecommerceArticleType
 * @returns {boolean}
 */
export const shouldDisplayEcommerceDisclaimerAlternateFormat = (ecommerceArticleType) => {
  const shouldDisplayAlternate = ecommerceArticleType === 'nbcNewsGeneralCommerceStackCommerce';
  return shouldDisplayAlternate;
};
