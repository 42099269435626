import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { RouteContext, useFeatureFlagContext } from 'lib/ContextTypes';
import { stub as $t } from '@nbcnews/analytics-framework';

const LAZY_COMPONENT_RENDERED = 'lazy_component_rendered';

$t('register', LAZY_COMPONENT_RENDERED);

/**
 * Lazy renders a component when it enters the viewport.
 * @template T
 * @param {import('react').ComponentType<T>} WrappedComponent
 * @param {number} minHeight
 * @param {(props: T, shouldShowComponent: boolean, defaultId: string) => string} getId
 * @returns {import('react').ComponentType<T>}
 */
export const withViewportVisibility = (WrappedComponent, minHeight, getId) => {
  /**
   * @param {T} props
   */
  const ComponentWithVisibility = (initialProps) => {
    const props = {
      minHeight: 'min-content',
      elementId: '',
      ...initialProps,
    };
    const { elementId = '' } = props;
    const { ref, inView } = useInView({
      initialInView: false,
      rootMargin: '200px 0px',
      threshold: 0,
      triggerOnce: true,
    });

    const { 'lazy-render-commerce-components': lazyRenderCommerceComponents } = useFeatureFlagContext();

    const route = React.useContext(RouteContext);
    const isAmp = route.path.indexOf('embedded-pkg') !== -1;

    React.useEffect(() => {
      if (inView && lazyRenderCommerceComponents) {
        $t('track', LAZY_COMPONENT_RENDERED, {
          component: WrappedComponent.displayName || WrappedComponent.name || 'unknown',
          el: ref.current,
        });
      }
    }, [inView, lazyRenderCommerceComponents]);

    const shouldShowComponent = (inView && lazyRenderCommerceComponents)
      || isAmp
      || !lazyRenderCommerceComponents;
    const defaultId = elementId ? `anchor-${elementId}` : undefined;
    const id = getId ? getId(props, shouldShowComponent, defaultId) : defaultId;

    return (
      <div ref={ref} style={{ minHeight }} id={id}>
        {
          shouldShowComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            ? <WrappedComponent {...props} />
            : null
        }
      </div>
    );
  };

  ComponentWithVisibility.propTypes = {
    minHeight: PropTypes.string,
    elementId: PropTypes.string,
  };

  // Set display name for better debugging in React DevTools
  ComponentWithVisibility.displayName = `withViewportVisibility(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return ComponentWithVisibility;
};
