import React, { useEffect } from 'react';
import i18next from 'i18next';
import {
  getFormattedDateString,
  getFormattedDateTimeString,
  getFormattedDateYearString,
  getFormattedMonthAndDayString,
} from 'lib/DateTime';

/**
 * Timestamp component to display formatted date and time.
 *
 * @param {object} props - Component properties.
 * @param {boolean} [props.dateOnly] - Flag to indicate if only the date should be displayed.
 * @param {string} [props.dateString] - The date string to be formatted.
 * @param {string} [props.delimiter] - Delimiter to separate date and time.
 * @param {string} [props.layout] - Layout type for the timestamp display.
 * @param {string} [props.timeZone] - Time zone for date formatting.
 */
function Timestamp(props) {
  const {
    dateOnly = false,
    dateString = '',
    delimiter = ' / ',
    layout,
    timeZone,
  } = props;
  const [timestampToRender, setTimestampToRender] = React.useState(null);
  const [formattedMonthAndDay, setFormattedMonthAndDay] = React.useState(null);
  const [formattedYear, setFormattedYear] = React.useState(null);

  useEffect(() => {
    const formattedDateString = getFormattedDateString(dateString, timeZone, {}, i18next);
    const formattedDateTime = getFormattedDateTimeString(dateString, timeZone, delimiter, i18next);

    setTimestampToRender(dateOnly ? formattedDateString : formattedDateTime);
    setFormattedMonthAndDay(getFormattedMonthAndDayString(dateString, timeZone, i18next));
    setFormattedYear(getFormattedDateYearString(dateString, timeZone));
  }, [dateString, timeZone, dateOnly, delimiter]);

  if (dateString === '') {
    return null;
  }

  return (
    <>
      {layout === 'split' ? (
        <>
          <span className="dib mr1 ws-nowrap" data-test="timestamp__monthDay" data-testid="timestamp__monthDay">
            {formattedMonthAndDay}
            {delimiter}
          </span>
          <span className="dib ws-nowrap" data-test="timestamp__year">
            {formattedYear}
          </span>
        </>
      ) : (
        timestampToRender
      )}
    </>
  );
}

export default Timestamp;
