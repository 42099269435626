import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isResizableImage } from 'lib/imageUtils';
import { Picture } from 'components/Picture';
import { layoutContext as layoutContextPropType } from 'lib/CustomPropTypes';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import './styles.themed.scss';

const block = 'background';

/**
 * Implements the Background page and content.
 * @param {object} props - props object
 * @param {node} props.children - The children of the component
 * @param {boolean} props.useBackground - Whether to use the background
 * @param {string} props.imageUrl - The image URL
 * @param {object} [props.styleOverride] - The style override object
 * @param {boolean} [props.animate] - Whether to animate the background
 * @returns {JSX.Element} The rendered Background component
 */
const Background = ({
  children,
  useBackground,
  imageUrl,
  styleOverride = {
    bgWrapper: '',
    bgPicture: '',
    bgBackground: '',
    bgGradient: '',
    bgColor: '',
  },
  animate = false,
}) => {
  /**
   * getGradient function to return a gradient div
   * @param {*} bgStyleOverride - The background style override
   * @param {*} i - The index
   * @returns {JSX.Element} The gradient div
   */
  const getGradient = (bgStyleOverride, i = 0) => (
    <div
      key={`${bgStyleOverride}${i}`}
      className={classNames(
        {
          'bg-background__gradient': !bgStyleOverride,
          [bgStyleOverride]: bgStyleOverride,
          hasImage: true,
        },
        'absolute absolute-fill',
      )}
      data-testid="bg-getGradient"
    />
  );

  /**
   * solid background color
   * @returns {JSX.Element} The solid background color
   */
  const color = () => (
    <div
      className={classNames(
        { 'bg-background__color': !styleOverride.bgColor },
        styleOverride.bgColor,
        'absolute absolute-fill',
      )}
      data-testid="background-color"
    />
  );
  /**
   * Image via picture component
   * @returns {JSX.Element} The picture component
   */
  const picture = () => {
    if (!isResizableImage(imageUrl)) {
      return '<!-- Must be resizable image -->';
    }
    return (
      <Picture
        url={imageUrl}
        flavor="fit"
        responsiveFlavors={{
          s: AIMS_FLAVORS.FIT_760w,
          m: AIMS_FLAVORS.FIT_1000w,
          xl: AIMS_FLAVORS.FIT_1500w,
        }}
        className={classNames(
          'bg-background__picture',
          styleOverride.bgPicture,
          'absolute absolute-fill overflow-hidden',
          {
            'bg-background__animate': animate,
          },
        )}
      />
    );
  };

  /**
   * Image via styled background image
   * @returns {JSX.Element} The background
   */
  const background = () => (
    <div
      className={classNames(
        'bg-background__picture',
        styleOverride.bgPicture,
        'w-100 h-100',
        {
          'bg-background__picture': animate,
        },
      )}
      style={{ backgroundImage: `url('${imageUrl}')` }}
      data-testid="background-image"
    />
  );
  /**
   * getBackground function to return the background
   * @returns {JSX.Element} The background
   */
  const getBackground = () => {
    // There is no image provided
    if (!imageUrl) {
      // Display background color
      return color();
    }

    let bgGradient = null;
    // explicitly pass `false` to disable background gradient entirely
    // TODO: [BENTO-9040] refactor this to avoid coercing type
    if (styleOverride.bgGradient !== false) {
      bgGradient = Array.isArray(styleOverride.bgGradient)
        ? styleOverride.bgGradient.map(getGradient)
        : getGradient(styleOverride.bgGradient);
    }

    return (
      <>
        <div
          className={classNames(
            { 'bg-background': useBackground },
            styleOverride.bgBackground,
          )}
        >
          {useBackground ? background() : picture()}
        </div>
        {bgGradient}
      </>
    );
  };

  return (
    <div
      data-testid="background"
      className={classNames(
        block,
        'bg-background__wrapper',
        styleOverride.bgWrapper,
        {
          'bg-use-background': useBackground,
        },
      )}
    >
      {getBackground()}
      {!!children && (
        <div
          className={classNames(
            styleOverride.bgContent,
            {
              'relative df flex-column justify-center': useBackground,
            },
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Background.propTypes = {
  children: PropTypes.node,
  useBackground: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
  styleOverride: PropTypes.shape({
    bgWrapper: PropTypes.string,
    bgPicture: PropTypes.string,
    bgBackground: PropTypes.string,
    bgGradient: PropTypes.oneOfType([
      PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.bool,
    ]),
    bgContent: PropTypes.oneOfType([
      PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.bool,
    ]),
    bgColor: PropTypes.string,
  }),
  animate: PropTypes.bool,
};

Background.contextTypes = {
  ...layoutContextPropType,
};

export default Background;
