import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { image as imagePropType } from 'lib/CustomPropTypes';
import { replaceAmpersands } from 'lib/stringUtils';

import './styles.themed.scss';

const Caption = ({
  className = '',
  image = {},
  id = null,
}) => {
  const {
    caption,
    authors,
    source,
  } = image || {};

  // Image rights
  let rights;

  // Check if author value is empty string
  const hasAuthor = get(authors, '[0].name.length', false);

  // Check if source value is empty string.
  const hasSource = get(source, 'name.length', false);

  if (hasAuthor) {
    rights = `${authors[0].name}${hasSource ? ` / ${source.name}` : ''}`;
  } else if (hasSource) {
    rights = source.name;
  }

  if (caption || rights) {
    const captionClasses = classNames(
      'caption',
      className, // Overrides from parent component
    );

    return (
      <figcaption className={captionClasses} data-testid="caption" id={id}>
        {caption && (
          <span className="caption__container" data-testid="caption__container">
            {replaceAmpersands(caption)}
          </span>
        )}
        {rights && (
          <span className="caption__source" data-testid="caption__source">
            {replaceAmpersands(rights)}
          </span>
        )}
      </figcaption>
    );
  }

  return null;
};

Caption.propTypes = {
  className: PropTypes.string,
  image: imagePropType,
  id: PropTypes.string,
};


export default Caption;
