import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { useVertical } from 'lib/Hooks';

import { layoutContext as LayoutContextPropType, packageContext as packageContextPropType } from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  SEE_MORE_BUTTON,
} from 'lib/brandFeatures';

import Link from 'components/Link';
import TitleLogo from 'components/TitleLogo';

import styles from './styles.module.scss';
import './styles.themed.scss';

const titleColorArray = ['blue', 'green', 'yellow'];

/**
 * PackageTitle component renders the title section of a package.
 *
 * @param {object} props - The component props.
 * @param {object} props.metadata - Metadata for the package title.
 * @param {boolean} [props.alternateDisplayFormat=false] - Flag for alternate display format.
 * @param {string} props.titleBgColor - Background color for the title.
 * @param {string} props.size - Size of the title.
 * @param {string} [props.className] - Additional class names for the component.
 * @param {string} [props.HTag='h2'] - HTML tag to be used for the title.
 * @param {object} context - The context object.
 * @returns {React.ReactElement|null} The rendered component or null if no title or logoUrl is provided.
 */
const PackageTitle = ({
  metadata = {},
  alternateDisplayFormat = false,
  titleBgColor,
  size,
  className,
  HTag = 'h2',
}, context = {}) => {
  const { vertical } = useVertical();

  if (!(metadata && (metadata.title || metadata.logoUrl))) {
    return null;
  }

  const {
    title, logoUrl, seeAllText, seeAllUrl, description, icid, anchorId,
  } = metadata;

  // TODO: Check if this is still needed
  const { layoutType, pkgTitleColorIndex } = context;

  const isRailLayout = layoutType === 'leftRail' || layoutType === 'rightRail';

  const isFullWidth = layoutType === 'fullWidth';

  const shouldRenderButton = getFeatureConfigForBrand(
    SEE_MORE_BUTTON,
    vertical,
  );
  const isNotAlternateDisplay = !alternateDisplayFormat;

  let titleColor = false;

  if (titleBgColor) {
    titleColor = titleBgColor;
  } else if (typeof pkgTitleColorIndex === 'number') {
    titleColor = titleColorArray[pkgTitleColorIndex];
  }

  const headingClasses = classNames(
    styles.titleText,
    'package-title__title-text',
    {
      [`package-title__title-text--${titleColor}`]: titleColor,
      [`package-title__title-text--${size}`]: size,
    },
  );

  const containerClasses = classNames(
    'package-title__container',
    'package-title',
    styles.packageTitle,
    {
      [styles.rail]: isRailLayout,
      [styles.fullWidth]: isFullWidth,
      'package-title--with-description': description,
      [styles.alternateDisplayFormat]: alternateDisplayFormat,
      'package-title--alternate-display-format': alternateDisplayFormat,
      [className]: className,
    },
  );

  const link = seeAllUrl && !shouldRenderButton
    ? (
      <Link
        className={classNames(
          'package-title__see-all-link',
          {
            'package-title__see-all-link--alt-color': shouldRenderButton && isNotAlternateDisplay,
          },
        )}
        to={seeAllUrl}
        data-test="package-title-see-all"
        icid={icid}
      >
        {seeAllText || i18next.t('See More')}
      </Link>
    )
    : null;

  return (
    <div className={containerClasses} data-test="package-title">
      <HTag className={headingClasses} data-test="package-title-title" id={anchorId}>
        <TitleLogo logoUrl={logoUrl} title={title} />
      </HTag>
      {link}
      {description && (
        <p className="package-title__description">
          {description}
        </p>
      )}
    </div>
  );
};

PackageTitle.contextTypes = {
  ...LayoutContextPropType,
  ...packageContextPropType,
};

export default PackageTitle;
