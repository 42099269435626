import React from 'react';
import PropTypes from 'prop-types';
import './styles.themed.scss';

function renderTitle({ title, className }) {
  if (Array.isArray(title)) {
    return title.map((t) => (
      <div className={className} key={t}>
        {t}
      </div>
    ));
  }
  return title;
}

const TitleLogo = ({
  alt = '',
  logoUrl = null,
  title = '',
  className = '',
}) => (logoUrl ? (
  <img className="titleLogo" src={logoUrl} alt={alt} data-testid="title-logo" />
) : renderTitle({ title, className }));

TitleLogo.propTypes = {
  alt: PropTypes.string,
  logoUrl: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};


export default TitleLogo;
