import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import styles from './styles.module.scss';

const Author = ({
  card: {
    computedValues, id, item, icid,
  } = {},
}) => {
  // eslint-disable-next-line max-len
  const defaultAuthorPhotoUrl = 'https://media-cldnry.s-nbcnews.com/image/upload/newsgroup-logos/msnbc/columnists/default-square-120x120.png';

  const headlineText = computedValues?.headline ?? '';
  const articlePageUrl = computedValues?.url ?? undefined;

  const author = item?.authors?.[0]?.person ?? {};
  const authorName = author?.name ?? '';
  const authorPageUrl = author?.url?.primary ?? '';
  const authorPhotoUrl = author?.primaryImage?.url?.primary ?? defaultAuthorPhotoUrl;

  return (
    <div
      className={styles.baconCardsAuthor}
      data-test="baconCardsAuthorContainer"
      data-contentid={id}
    >
      {authorPhotoUrl && (
        <div className={styles.baconCardsAuthorPhotoGroup}>
          {authorPageUrl ? (
            <Link
              to={authorPageUrl}
              className={styles.baconCardsAuthorPhotoLink}
              data-test="baconCardsAuthorPhotoLink"
              icid={icid}
            >
              <img src={authorPhotoUrl} alt={authorName} className={styles.baconCardsAuthorPhoto} />
            </Link>
          ) : (
            <img src={authorPhotoUrl} alt={authorName} className={styles.baconCardsAuthorPhoto} />
          )}
        </div>
      )}
      <div className={styles.baconCardsAuthorTextGroup}>
        {
          authorPageUrl ? (
            <Link
              to={authorPageUrl}
              className={styles.baconCardsAuthorPhotoLink}
              data-test="baconCardsAuthorPhotoLink"
              icid={icid}
            >
              <span
                className={styles.baconCardsAuthorName}
                data-test="baconCardsAuthorName"
              >
                <span>{authorName}</span>
              </span>
            </Link>
          ) : (
            <span
              className={styles.baconCardsAuthorName}
              data-test="baconCardsAuthorName"
            >
              <span>{authorName}</span>
            </span>
          )
        }
        <div
          className={styles.baconCardsAuthorHeadline}
          data-test="baconCardsAuthorHeadline"
        >
          {articlePageUrl ? (
            <Link
              to={articlePageUrl}
              className={styles.baconCardsArticleLink}
              data-test="baconCardsArticleLink"
              icid={icid}
            >
              {headlineText}
            </Link>
          ) : (
            headlineText
          )}
        </div>
      </div>
    </div>
  );
};

Author.contextTypes = {
  vertical: PropTypes.string,
};

Author.propTypes = {
  card: ContentPropType,
};


export { Author };
