/**
 *
 * @param {string} sourceName
 * @param {string} subType
 * @returns {boolean}
 */
export const shouldTargetBeBlankHelper = (sourceName, subType) => {
  const isSourceSports = sourceName === 'NBC Sports';
  const isSubTypeExternalLink = subType === 'externalLink';
  return isSubTypeExternalLink && isSourceSports;
};
