import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Background from 'components/Background';
import { CardOverlay } from 'components/CardOverlay';
import { SHOW_2x3_CARD_GRADIENT as showGradient, SHOW_2x3_BUTTON_CARET as showCaret } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import Link from 'components/Link';


import contentPropType from 'lib/CustomPropTypes/content';
import trimText from 'lib/trimText';
import { hasTeaseImage, getTeaseUrl } from 'lib/teaseUtils';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getOptimizedImageUrl } from 'lib/imageUtils';
import { VerticalContext } from 'lib/ContextTypes';
import styles from './styles.module.scss';

// TODO: Update this image map to urls without hashes when the CMS supports that functionality
const FALLBACK_IMG_URL_MAP = {
  /* eslint-disable max-len */
  better: 'https://media4.s-nbcnews.com/i/newscms/2019_23/2887891/2x3_better_fallback_image_75962cb529381dae91e4953bf772b192.jpg',
  globalcitizen: 'https://media3.s-nbcnews.com/i/newscms/2019_23/2887896/2x3_global-citizen_fallback_image_75962cb529381dae91e4953bf772b192.jpg',
  mach: 'https://media2.s-nbcnews.com/i/newscms/2019_23/2887911/2x3_mach_fallback_image_75962cb529381dae91e4953bf772b192.jpg',
  msnbc: 'https://media1.s-nbcnews.com/i/newscms/2019_23/2887916/2x3_msnbc_fallback_image_75962cb529381dae91e4953bf772b192.jpg',
  news: 'https://media4.s-nbcnews.com/i/newscms/2019_23/2887921/2x3_nbcnews_fallback_image_75962cb529381dae91e4953bf772b192.jpg',
  think: 'https://media1.s-nbcnews.com/i/newscms/2019_23/2887926/2x3_think_fallback_image_d1b83678453221b54caeeaba1d24d1b5.jpg',
  today: 'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/sunrise-846ed5.svg',
  /* eslint-enable max-len */
};

function getBackgroundPicture(computedValues, vertical = 'news') {
  if (!hasTeaseImage(computedValues)) {
    Object.assign(computedValues, {
      alternateTeaseImages: [{
        aspectRatio: 'ASPECT_2X1',
        image: {
          altText: vertical,
          url: {
            primary: FALLBACK_IMG_URL_MAP[vertical.toLowerCase()],
          },
        },
      }],
    });
  }
  const teaseUrl = getTeaseUrl({ computedValues });
  return getOptimizedImageUrl(teaseUrl, AIMS_FLAVORS.FOCAL_240x360, 'best', 2);
}

const title = (headline, hedAndDekAreEmpty) => (
  <div
    data-test="title"
    data-testid="title"
    className={classNames(styles.baconCardsTwoByThreeTitle, {
      [styles.noBG]: hedAndDekAreEmpty,
    })}
  >
    {trimText(headline, 45)}
  </div>
);

const description = (dek) => (
  <div className={styles.baconCardsTwoByThreeDescription} data-test="dek" data-testid="dek">
    {trimText(dek, 220)}
  </div>
);

const button = ({
  url, target, productId, renderCaret,
}) => {
  const { t } = useTranslation();
  return (
    <Link
      data-product-id={productId}
      data-test="button"
      data-testid="button"
      href={url}
      className={styles.baconCardsTwoByThreeButton}
      target={target}
    >
      {t('VIEW')}
      {renderCaret && (
        <span
          className={classNames(styles.caret, 'icon', 'icon-chevron-right')}
          aria-hidden="true"
          focusable="false"
          data-testid="caret"
          data-test="caret"
        />
      )}
    </Link>

  );
};

const TwoByThree = ({
  card = {},
  openInNewTab = false,
}) => {
  const vertical = React.useContext(VerticalContext);
  const {
    computedValues: {
      dek,
      headline,
      url,
      id,
    } = {},
  } = card;

  const cardOverlayContainer = classNames(
    { [styles.baconCardsTwoByThreeOverlay]: !!(dek?.length) },
  );

  const onlySpaces = /^\s+$/;
  const noDek = !dek || onlySpaces.test(dek);
  const hedAndDekAreEmpty = noDek && onlySpaces.test(headline);
  const target = openInNewTab ? '_blank' : '_self';
  const useGradientBG = !hedAndDekAreEmpty && getFeatureConfigForBrand(
    showGradient,
    vertical,
  );

  return (
    <div
      className={styles.baconCardsTwoByThreeContainer}
      data-test="container"
      data-testid="container"
      data-contentid={id}
    >
      {headline
        && (
          <div className={styles.baconCardsTwoByThreeTitleWrapper}>
            <Link
              data-product-id={id}
              href={url}
              className={styles.baconCardsTwoByThreeTitleLink}
              target={target}
            >
              {title(headline, hedAndDekAreEmpty)}
            </Link>
          </div>
        )}
      <Background
        imageUrl={getBackgroundPicture(card.computedValues, vertical)}
        useBackground
        styleOverride={{
          bgContent: styles.baconCardsTwoByThreeBgContent,
          bgWrapper: styles.baconCardsTwoByThreeBgWrapper,
          bgGradient: useGradientBG
            ? [styles.baconCardsTwoByThreeGradient, styles.baconCardsTwoByThreeAdditionalGradient]
            : false,
        }}
        data-test="background"
      >
        {!noDek
          && (
            <CardOverlay
              additionalClasses={{ container: cardOverlayContainer }}
              activeOnHover
            >
              {description(dek)}
              {button({
                url,
                target,
                productId: id,
                renderCaret: getFeatureConfigForBrand(
                  showCaret,
                  vertical,
                ),
              })}
            </CardOverlay>
          )}
      </Background>
    </div>
  );
};

TwoByThree.contextTypes = {
  vertical: PropTypes.string,
};

TwoByThree.propTypes = {
  card: contentPropType,
  openInNewTab: PropTypes.bool,
};


export { TwoByThree };
